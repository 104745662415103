<template>
  <div v-if="menu">
    <transition name="fade">
      <div>
        <div style="width:1152px">
          <div class="card-wrapper" v-if="grades.length>0" :style="`grid-template-columns:repeat(${grid}, 1fr)`">
            <component :is="`${componentName}Pc`" v-for="(card, idx) in grades" :key="'card'+idx" :card="card"></component>
          </div>
          <template v-if="grades1.length>0 && single" >
            <div class="h5 padding-top-40 padding-bottom-16">수동 설치 {{ menu.name }}
              <span class="h5 primary margin-left-4">{{ grades1.length }}</span></div>
            <div class="body2 sub3 margin-bottom-32">기능 추가를 원할 시 {{ menu.name === '플랫폼 팩' ? '최소 4주의' : '2주 이내의' }} 작업 기간이 필요합니다. 런치팩 1:1 문의를 통해 신청해주시면 필요한 절차와 세부 설치 일정을 안내해드립니다. </div>

            <div class="card-wrapper" :style="`grid-template-columns:repeat(${grid}, 1fr)`">
              <component :is="`${componentName}Pc`" v-for="(card, idx) in grades1" :key="'card1-'+idx" :card="card"></component>
            </div>
          </template>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
  import CardProductThemePc from "./CardProductThemePc";
  import CardProductPluginPc from "./CardProductPluginPc";
  import CardProductServicePc from "./CardProductServicePc";

  export default {
    name: "ListProductAppMarket",
    components: {
      CardProductThemePc,
      CardProductPluginPc,
      CardProductServicePc
    },
    props: {
      menu: {
        type: Object
      },
      products: {
        type: Array
      },
      shadow: {
        type: Boolean,
        default: false
      },
      single: {
        type: Boolean,
        default: false
      }
    },
    created() {
      this.initCard();
    },
    data() {
      return {
        grid: 3,
        cards: [],
        avail_color: [],
        colors: ['#0ead9d', '#d0ae45', '#f45853', '#393e46', '#ff6600'],
        ishover: false
      }
    },
    watch: {
      products(n) {
        this.cards = n;
      },
      menu() {
        this.initCard();
      }
    },
    computed: {
      grades() {
        let cards = this.single ? this.cards.filter(i => i.grade === 0) : this.cards;
        return this.$store.getters.userService ? cards.filter(i => i.params.find(p => p.name === 'key').value !== this.$store.getters.userService.package) : cards;
      },
      grades1() {
        return this.cards.filter(i => { return i.grade === 1 });
      },
      componentName() {
        const name = this.menu.name;
        let type = 'Theme';
        if(name === '플러그인') {
          type = 'Plugin';
        }
        else if(name === '플랫폼 팩') {
          type = 'Theme';
        }
        else {
          type = 'Service';
        }

        return `CardProduct${type}`;
      },
      boxShadow() {
        if (this.shadow) return 'box-shadow: 0px 2px 10px 0 #ccc8c8;';
        else return '';
      }
    },
    methods: {
      initCard() {
        if(this.menu && this.menu.name === '플랫폼 팩') {
          this.grid = 4;
        }
      }
    }
  }
</script>

<style lang="stylus" scoped>
  @import '~assets/css/lp_main'

  .card-wrapper
    display grid
    grid-gap 57px 16px

</style>
